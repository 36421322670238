@import url('https://fonts.googleapis.com/css2?ffamily=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

* {
    box-sizing: border-box;
}

/*------------------*/
/* variables
/*------------------*/
body {
    --text_color: #CDE3F4;
    --title_color: #d2d6d9;
    /*--title_color: #F5FAFD;*/
    /*--bg_body: linear-gradient(90deg, #071626 0%, #113354 150%);*/
    --bg_body: #021324;
    --bg_nav_tree: #071626;
    --border_color: #172D4D;
    --bg_thead: #0f2030;
    --level_color: #00959E;
    --bg_level: rgb(0 149 158 / 12%);
    --link_color: cornflowerblue;
    --link_color_hover: orange;
    --bg_input: none;
    --border_input: #172D4D;
    --placeholder_color: #6687A0;
    --marker_color: #6687A0;
    --selected: linear-gradient(90deg, #2F80ED -1160%, rgb(0 149 158 / 0%) 150%);

}

html,
body {
    padding: 0;
    margin: 0;
    color: var(--text_color);
    background: var(--bg_body);
    font-size: 14px;

    font-family: 'Ubuntu', serif;
}

h1, h2, h3, h4, h5 {
    color: var(--title_color);
}

select {

    color: #68DCE4;

    /* styling */
    background-color: #131F2E;
    border: thin solid #1D2C4A;
    border-radius: 4px;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;
    cursor: pointer;

    /* reset */

    margin: 0;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image:
            linear-gradient(45deg, transparent 50%, gray 50%),
            linear-gradient(135deg, gray 50%, transparent 50%),
            linear-gradient(to right, #1D2C4A, #1D2C4A);
    background-position:
            calc(100% - 18px) calc(1em + 2px),
            calc(100% - 13px) calc(1em + 2px),
            calc(100% - 2.6em) 0.5em;
    background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
    background-repeat: no-repeat;
}

p {
    line-height: 1.6;
}

li {
    padding: 2px;
    margin-bottom: 4px;
    line-height: 1.4;
}

li::marker {
    font-size: 130%;
    color: var(--marker_color);
}

li p {
    margin: 0;
}

textarea:focus, input:focus {
    outline: none;
}


/*------------------*/
/* links
/*------------------*/

a {
    color: var(--link_color);
    text-decoration: none;
}

a:hover {
    color: var(--link_color_hover);
    text-decoration: none;
}


/*------------------*/
/* tables
/*------------------*/

table, th, td {
    border: 1px solid var(--border_color);
    white-space: nowrap;
}

table {
    border-collapse: collapse;
}

thead {
    background-color: var(--bg_thead);
    color: var(--text_color);
}

th {
    padding: 8px 6px;
    white-space: pre;
}
@media (min-width: 1024px) {
    th {
        white-space: normal;
    }
}

tr {
}

td {
    padding: 8px 6px;
}

hr {
    border-color: var(--border_color);
    border-width: 0.5px;
}

button {
    background-color: transparent;
    border: 1px solid #555;
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
    color: #aaa
}

button:hover {
    background-color: #222;
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    position: relative;
    z-index: 3;
}

@media (min-width: 767px) {
    header {
        display: none;
    }
}

/*------------------*/
/* application root
/*------------------*/

.App {
    display: flex;
    overflow: hidden;
    height: 100vh;
    backface-visibility: hidden;
    will-change: overflow;
}

/*------------------*/
/*  side bar
/*------------------*/

@media (min-width: 767px) {
    .side-bar {
     display: none;
    }
}
.side-bar, .side-bar:hover, .side-bar:active {
    border: none;
    background-color: transparent;
}
.side-bar-show {
    display: block !important;
}

.menu-btn {
    width: 22px;
    height: 2px;
    background-color: var(--text_color);
    margin: 6px 0;
    transition: 0.4s;
}

.cross .menu-btn:nth-child(1) {
    transform: translate(0, 8px) rotate(-45deg);
}

.cross .menu-btn:nth-child(2) {
    opacity: 0;
}

.cross .menu-btn:nth-child(3) {
    transform: translate(0, -9px) rotate(45deg);
}

/*------------------*/
/* content page
/*------------------*/

.content-panel {
    display: flex;
    flex-direction: column;
    justify-content: start;
    flex-grow: 1;
    overflow: auto;
    /*line-height: 1.5em;*/
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
}

@media (min-width: 767px) {
    .content-panel {
        justify-content: center;
        flex-direction: row
    }
}

.ContentPage {
    /*overflow: auto;*/
    /*outline: 1px solid red;*/
    height: fit-content;
    padding: 0 10px 40px;
    flex-grow: 1;
    width: 100%;
    max-width: 1024px;
}

@media (min-width: 767px) {
    .ContentPage {
        padding: 20px 10px 40px;
    }
}

.SourcePage {
    padding: 20px 40px 40px;
    flex-grow: 1;
    max-width: 900px;
    background-color: #080808;
}

.SourcePage > pre {
    white-space: pre-wrap;
}

.style_img_top, .style_img {
    border: 1px solid var(--border_color);
    border-radius: 4px;
}

/*------------------*/
/* navigation menu
/*------------------*/

.NavigationTree {
    overflow: auto;
    height: 100vh;
    padding: 20px 8px;

    background-color: var(--bg_nav_tree);
    scrollbar-gutter: stable;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    border-right: 1px solid var(--border_color);
    position: fixed;
    z-index: 1;
    min-width: 100%;
    display: none;
    margin-top: 60px;
}

.navigationTitle {
    display: none;
    white-space: nowrap;
    margin-bottom: 8px;
}

@media (min-width: 767px) {
    .NavigationTree {
        display: block;
        position: relative;
        min-width: 256px;
        margin-top: 0;
    }

    .navigationTitle {
        display: block;
    }
}

.nav-node-folder, .nav-node-leaf {
    transition: background-color .2s ease;
}

.nav-node-leaf a {
    width: 100%;
}

.nav-node-folder span, .nav-node-leaf span {
    margin-right: 12px;
}

.nav-node-folder:hover, .nav-node-leaf:hover {
    background: var(--selected);
    /*border-left: 2px solid transparent;*/
}


.nav-node-folder a, .nav-node-leaf a {
    color: var(--text_color);
    display: flex;
    align-items: center;
}

.nav-node-folder {
    margin-top: 4px;
    padding: 2px 6px;
    border-left: 4px solid transparent;
    height: 34px;
    display: flex;
    align-items: center;
}

.nav-node-folder img {
    width: 14px;
    height: 14px;
    margin-left: 4px;
}

.nav-node-leaf {
    height: 34px;
    display: flex;
    align-items: center;
    padding: 2px 6px;
    border-left: 4px solid transparent;
}

.nav-node-selected {
    background: var(--selected);
    border-radius: 4px;
    border-left: 4px solid cornflowerblue;
}

.nav-node-draft {
    color: brown;
}

/*------------------*/
/* links with icons
/*------------------*/

.link-invalid {
    background-color: darkred;
    color: orange;
    padding: 2px 4px;
}

/*.link-icon {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    align-items: center;*/
/*}*/

.link-icon::before {
    position: relative;
    top: 4px;
    background-size: 18px 18px;
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    display: inline-block;
    content: "";
    margin-right: 4px;
}

.link-build::before {
    background-image: url('./icons/building_icon.png');
}


/*------------------*/
/* requirements
/*------------------*/

.req-container {
    display: flex;
    flex-flow: column;
    margin-top: -2px;
    padding-bottom: 2px;
}

.req-level {
    margin-left: 3px;
    border: 1px solid var(--level_color);
    background-color: var(--bg_level);
    color: var(--level_color);
    /*color: white;*/
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 12px;
    font-weight: bold;
}


/*------------------*/
/* resources
/*------------------*/

.res-container {
    /*outline: 0.5px solid red;*/
    display: flex;
    align-items: stretch;
    height: 36px;
}

.res-item {
    flex-basis: 100%;
    flex-wrap: nowrap;
    display: flex;
    gap: 2px;
    border-right: 1px solid var(--border_color);
    padding-right: 4px;
    margin-right: 4px;
    align-items: center;
}

.res-item:last-child {
    border: none;
}

.res-label {
    margin-right: 6px;
}

td:has(> div.res-container) {
    padding-top: 0;
    padding-bottom: 0;
}

.res-icon {
    width: 18px;
    height: 18px;
    background-size: 100% 100%;
    margin-right: 2px;
}

.res-icon-cp {
    background-image: url('./icons/cp_icon.png');
}

.res-icon-nf {
    background-image: url('./icons/nf_icon.png');
}

.res-icon-wx {
    background-image: url('./icons/wx_icon.png');
}

.res-icon-wc {
    background-image: url('./icons/wc_icon.png');
}


/*.res-icon::before {*/
/*	background-size: 16px 16px;*/
/*	overflow: visible;*/
/*	width: 16px;*/
/*	height: 20px;*/
/*	background-position-y: 4px;*/
/*	background-position-x: 0;*/
/*	background-repeat: no-repeat;*/
/*	display: inline-block;*/
/*	content: "";*/
/*}*/

.res-cp::before {
    background-image: url('./icons/cp_icon.png');
}

.res-nf::before {
    background-image: url('./icons/nf_icon.png');
}

.res-wx::before {
    background-image: url('./icons/wx_icon.png');
}

.res-wc::before {
    background-image: url('./icons/wc_icon.png');
}


/*------------------*/
/* units types
/*------------------*/

.unit-robot::before {
    background-image: url('./icons/robot_icon.png');
}

.unit-drone::before {
    background-image: url('./icons/drone_icon.png');
}

.unit-spy::before {
    background-image: url('./icons/spy_icon.png');
}

.unit-ram::before {
    background-image: url('./icons/ram_icon.png');
}

.unit-art::before {
    background-image: url('./icons/art_icon.png');
}

.unit-settler::before {
    background-image: url('./icons/settler_icon.png');
}

.unit-hacker::before {
    background-image: url('./icons/hacker_icon.png');
}


/*------------------*/
/* fractions
/*------------------*/

.fr-fr1::before {
    background-image: url('./icons/fr1_icon.png');
}

.fr-fr2::before {
    background-image: url('./icons/fr2_icon.png');
}

.fr-fr3::before {
    background-image: url('./icons/fr3_icon.png');
}


/*------------------*/
/* properties
/*------------------*/

.prop-attack::before {
    background-image: url('./icons/base_attack_icon.png');
}

.prop-air-defense::before {
    background-image: url('./icons/air_defence_icon.png');
}

.prop-gnd-defense::before {
    background-image: url('./icons/gnd_defence_icon.png');
}

.prop-consumption::before {
    background-image: url('./icons/wc_consum_icon.png');
}

.prop-spy-attack::before {
    background-image: url('./icons/spy_attack_icon.png');
}

.prop-spy-defense::before {
    background-image: url('./icons/spy_defence_icon.png');
}

.prop-capacity::before {
    background-image: url('./icons/capacity_icon.png');
}

.prop-speed::before {
    background-image: url('./icons/speed_icon.png');
}

.prop-time::before {
    background-image: url('./icons/build_time_icon.png');
}

.prop-upgrade::before {
    background-image: url('./icons/upgrade_icon.png');
}

.prop-level::before {
    background-image: url('./icons/level_icon.png');
}

.prop-droids::before {
    background-image: url('./icons/droid_icon.png');
}

.prop-art-rank::before {
    background-image: url('./icons/art_rank_icon.png');
}


/*------------------*/
/* values
/*------------------*/

.val-duration {
    color: #18dfe6;
}

/*------------------*/
/* search box
/*------------------*/

input {
    color: var(--text_color);
    background-color: var(--bg_input);
    border: 1px solid var(--border_input);
    border-radius: 4px;
    width: 100%;
    height: 34px;
    padding-left: 8px;
    font-weight: bold;
}

input::placeholder {
    font-weight: normal;
    color: var(--placeholder_color);
}

input:focus::placeholder {
    color: transparent;
}

.input-box {
    position: relative;
}

.input-button {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 6px;
    right: 2px;
    background: transparent;
    border: 0;
    color: #555;
    cursor: pointer;
    padding: 4px;
}

.input-button:hover {
    color: #aaa;
    background: none;
}

.input-button:focus {
    outline: none;
}

.input-highlight {
    background-color: wheat;
    color: darkblue;
    border-radius: 2px;
}


/*------------------*/
/* scroll bars
/*------------------*/

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #080808;
}

::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
    background: #333;
}